import type React from "react";

import classNames from "classnames";

import { Field } from "./Field";

const Input = ({
  className,
  label,
  description,
  inline,
  ...rest
}: React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  description?: string;
  inline?: boolean;
}) => {
  return (
    <Field label={label} description={description} required={rest.required}>
      <input
        className={classNames(
          "px-3 py-2 rounded bg-[#F0F0F0] border-[#DFE2E4] border shadow-inner", // default classes
          {
            "inline-block": inline,
            "block w-full": !inline,
            "mb-4": !inline && !description,
            "mb-1": description,
          },
          className,
        )}
        {...rest}
      />
    </Field>
  );
};

export default Input;
